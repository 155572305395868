* {
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
  background-color: #f7f7f7;
}

.container {
  display: grid;
  row-gap: 30px;
  width: 84%;
  max-width: 640px;
  margin: 60px auto;
}

.container h1 {
  justify-self: center;
}

.container p {
  justify-self: center;
}

form {
  display: grid;
  row-gap: 30px;
  justify-content: center;
}

input {
  width: 250px;
  box-sizing: border-box;
  border: solid 1px #ddd;
  padding: 10px;
  font-size: 16px;
  outline: none;
  appearance: none;
  border-radius: 10px;
}

button {
  justify-self: center;
  border: none;
  background-color: #0d6efd;
  color: #fff;
  padding: 10px;
  font-size: 16px;
}

.google {
  background-color: #f7f7f7;
  font-size: 30px;
}

button:hover {
  cursor: pointer;
  opacity: 0.7;
}
p.top {
  font-size: 10px;
  justify-self: right;
}
button.logout {
  justify-self: right;
  margin-left: 10px;
}
a.title{
  color: black;
  text-decoration: none;
}
a.title:hover{
  color: black;
  text-decoration: none;
}
a.title:visited{
  color: black;
  text-decoration: none;
}